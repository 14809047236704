import React from 'react';
import { FaCode, FaSketch, FaAndroid } from 'react-icons/fa';

export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: 'development',
    text: `Clean and sustainable. All sites are coded with performance and functionality in mind, no matter if it's a small business landing page or large web application, the best practices and tools are used.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: 'design',
    text: `Aesthetic with a purpose. My websites are user-focused with the idea of using a beautiful design to attract the user and keep their attention. All designs look great on any screen no matter how large or small.`,
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: 'deploy',
    text: `From setting up the hosting and domain, with site maintenance and updates, the deployment process is a breeze.`,
  },
];
