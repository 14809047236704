import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Jobs from '../components/Jobs';
import Projects from '../components/Projects';
import Blogs from '../components/Blogs';
import SEO from '../components/SEO';

export default ({ data }) => {
  const {
    allStrapiProjects: { nodes: projects },
  } = data;

  return (
    <Layout>
      <SEO title="Home" description="Home" />
      <Hero />
      <Services />
      <Jobs />
      <Projects
        projects={projects}
        title="Some of my latest projects"
        showLink
      />
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiProjects(filter: { featured: { eq: true } }) {
      nodes {
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`;
